
import VueTypes from 'vue-types';

import news from '@/gql/queries/sections/news.gql';
import categoriesQuery from '@/gql/queries/globals/categories.gql';

export default {
  inheritAttrs: false,
  props: {
    heading: VueTypes.string,
    highlightedEntries: VueTypes.arrayOf({
      id: VueTypes.string,
      heading: VueTypes.string,
      locationEntry: VueTypes.arrayOf({
        uri: VueTypes.string,
      }),
      visual: VueTypes.arrayOf({
        kind: VueTypes.string,
      }),
    }),
    locationEntry: VueTypes.arrayOf({
      uri: VueTypes.string,
    }),
    showAll: VueTypes.bool.def(false),
  },
  data() {
    return {
      entries: null,
      newsTypes: [],
      newsCategories: [],
      stepSize: 24,
      amount: 1,
      entryCount: 0,
      loading: false,
      isMounted: false,
      filterOverride: null,
    };
  },
  async fetch() {
    await this.fetchEntries(false);

    const { categories: newsTypes } = await this.$cms.query({
      query: categoriesQuery,
      variables: {
        site: this.$site,
        group: 'newsTypes',
        id: null,
      },
    });
    this.newsTypes = newsTypes;

    const { categories: newsCategories } = await this.$cms.query({
      query: categoriesQuery,
      variables: {
        site: this.$site,
        group: 'newsCategories',
        id: this.filter.categories ? this.filter.categories : [],
      },
    });
    this.newsCategories = newsCategories;
  },
  computed: {
    entryLimit() {
      if (this.showAll) {
        return this.stepSize;
      }

      return 3;
    },
    filter() {
      if (this.filterOverride) return this.filterOverride;

      const filter = {};
      if (this.$route.query?.category) {
        filter.categories = [this.$route.query.category];
      }
      if (this.$route.query && this.$route.query['category[]']) {
        filter.categories =
          typeof this.$route.query['category[]'] === 'string'
            ? [this.$route.query['category[]']]
            : this.$route.query['category[]'];
      }
      if (this.$route.query?.type) {
        filter.type = this.$route.query.type;
      }

      return filter;
    },
    newsFilters() {
      let newsFilters = [];
      const { categories, type } = this.filter;
      if (categories) {
        newsFilters = newsFilters.concat(categories);
      }
      if (type) {
        newsFilters.push(type);
      }

      return newsFilters.length ? ['and', ...newsFilters] : null;
    },
  },
  watch: {
    newsFilters(newValue, oldValue) {
      if (
        this.isMounted &&
        JSON.stringify(newValue) !== JSON.stringify(oldValue)
      ) {
        this.fetchEntries();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  },
  methods: {
    updateFilters(filter) {
      this.filterOverride = filter;
    },
    async fetchEntries(showLoad = true) {
      this.loading = showLoad;
      const { entries, entryCount } = await this.$cms.query({
        query: news,
        variables: {
          site: this.$site,
          limit: this.entryLimit,
          relatedTo: this.newsFilters,
        },
      });
      this.entryCount = entryCount;
      this.entries = entries;
      this.loading = false;
    },
    async fetchMore() {
      const { entries, entryCount } = await this.$cms.query({
        query: news,
        variables: {
          site: this.$site,
          limit: this.entryLimit,
          offset: this.entries.length,
          relatedTo: this.newsFilters,
        },
      });
      this.entryCount = this.entryCount + entryCount;
      this.entries = [...this.entries, ...entries];
    },
  },
};
