
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    resultCount: VueTypes.number,
    types: VueTypes.array,
    categories: VueTypes.array,
    filter: VueTypes.shape({
      type: VueTypes.string,
      categories: VueTypes.arrayOf(VueTypes.string),
    }),
  },
  data() {
    return {
      overlayActive: false,
    };
  },
  computed: {
    filterId() {
      return this.filter?.type;
    },
  },

  methods: {
    addFilterOption(id) {
      const path = this.$route.path;
      const query = { ...this.$route.query, type: id };
      const filter = { ...this.filter, type: id };

      this.$emit('update-filters', filter);
      this.$router.replace({ path, query });
    },
    removeCategoryOption(id) {
      const path = this.$route.path;
      const categories = this.filter.categories.filter(
        (category) => category !== id,
      );
      const query = { ...this.$route.query, 'category[]': categories };
      if (query.category) delete query.category;
      const filter = { ...this.filter, categories };

      this.$emit('update-filters', filter);
      this.$router.replace({ path, query });
    },
    toggleOverlay(value) {
      this.overlayActive = value;
    },
  },
};
