import { render, staticRenderFns } from "./NewsCardLarge.vue?vue&type=template&id=28b2ffbb&scoped=true"
import script from "./NewsCardLarge.vue?vue&type=script&lang=js"
export * from "./NewsCardLarge.vue?vue&type=script&lang=js"
import style0 from "./NewsCardLarge.vue?vue&type=style&index=0&id=28b2ffbb&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b2ffbb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Visual: require('/workspace/app/components/media/Visual.vue').default,IconButton: require('/workspace/app/components/buttons/IconButton.vue').default})
