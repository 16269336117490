import { render, staticRenderFns } from "./NewsOverview.vue?vue&type=template&id=1c29ef60"
import script from "./NewsOverview.vue?vue&type=script&lang=js"
export * from "./NewsOverview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsFilter: require('/workspace/app/components/news/NewsFilter.vue').default,IconButton: require('/workspace/app/components/buttons/IconButton.vue').default,NewsCardLarge: require('/workspace/app/components/cards/NewsCardLarge.vue').default,Container: require('/workspace/app/components/layout/Container.vue').default,NewsGrid: require('/workspace/app/components/news/NewsGrid.vue').default,PillButton: require('/workspace/app/components/buttons/PillButton.vue').default,Module: require('/workspace/app/components/layout/Module.vue').default})
