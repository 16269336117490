import { render, staticRenderFns } from "./NewsFilter.vue?vue&type=template&id=04149eae&scoped=true"
import script from "./NewsFilter.vue?vue&type=script&lang=js"
export * from "./NewsFilter.vue?vue&type=script&lang=js"
import style0 from "./NewsFilter.vue?vue&type=style&index=0&id=04149eae&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04149eae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/workspace/app/components/icons/Icon.vue').default,PillButton: require('/workspace/app/components/buttons/PillButton.vue').default,IconButton: require('/workspace/app/components/buttons/IconButton.vue').default})
